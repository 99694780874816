<template>
  <FloatLabel
    v-model:value="selectedEmail"
    class="w-full"
    id="contact-email"
    data-testid="contact-email"
    :placeholder="t('placeholder.type', { property: t('supplier.contacts.email').toLowerCase() })"
    :class="{ 'p-invalid': val.email.$error }"
    :selectAllOnFocus="true"
    :label="t('supplier.contacts.email')"
    maxlength="8000"
  />
  <small class="p-error" v-if="val.$error" data-testid="contact-email-error">
    {{ val.email.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import { helpers } from "@vuelidate/validators";
import { SupplierContact } from "@/repositories/supplier/model/SupplierContact";
import { email as validateEmail } from "@/locales/i18n-validators";
import { computed } from "vue";
const { t } = useI18n();

const props = defineProps<{
  email: string;
  contact: SupplierContact;
  contacts: SupplierContact[];
}>();

const emit = defineEmits<{
  (e: "update:email", value: string): void;
}>();

const selectedEmail = computed<string>({
  get: () => {
    return props.email ?? "";
  },
  set: (value) => {
    emit("update:email", value);
  },
});

const isUniqueEmail = (email: string) => {
  if (email.trim().length === 0) return true;

  const duplicateContact = props.contacts.find(
    (c) => c.email.toLowerCase() === email.toLowerCase() && c.id !== props.contact.id
  );
  return duplicateContact === undefined;
};

const rules = {
  email: {
    validateEmail: helpers.withMessage(() => t("validations.email"), validateEmail),
    unique: helpers.withMessage(t("validations.exists", { property: t("supplier.contacts.email") }), isUniqueEmail),
  },
};

const val = useVuelidate(rules, props);
</script>
