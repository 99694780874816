<template>
  <div class="p-float-label">
    <Dropdown
      id="supplier-document-language"
      :options="documentLanguages"
      optionLabel="name"
      optionValue="id"
      data-testid="supplier-document-language"
      v-model="selectedDocumentLanguage"
      class="w-full"
      :class="{ 'p-invalid': val.documentLanguage.$error }"
      :placeholder="t('placeholder.select', { property: t('supplier.document-language').toLowerCase() })"
      @show="isActive = true"
      @hide="isActive = false"
      @focus="isDropdownFocused = true"
      @blur="isDropdownFocused = false"
      @keydown.esc="isActive === true ? $event.stopPropagation() : ''"
    />
    <label
      for="supplier-document-language"
      class="c-required c-floating-label"
      :class="{ 'c-focused': isDropdownFocused }"
    >
      {{ t(`supplier.document-language`) }}
    </label>
    <small
      id="supplier-document-language-help"
      data-testid="supplier-document-language-error"
      class="p-error"
      v-if="val.documentLanguage.$error"
      >{{ val.documentLanguage.$errors[0].$message }}</small
    >
  </div>
</template>

<script setup lang="ts">
import { documentLanguageRepository } from "@/repositories/document-language/DocumentLanguageRepository";
import { DocumentLanguage } from "@/repositories/document-language/model/DocumentLanguage";
import { useVuelidate } from "@vuelidate/core";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { required } from "@/locales/i18n-validators";

const { t } = useI18n();

const props = defineProps<{
  documentLanguage: string;
}>();

const emit = defineEmits<{
  (e: "update:documentLanguage", value: string): void;
}>();

const isActive = ref(false);

const selectedDocumentLanguage = computed<string>({
  get: () => {
    return props.documentLanguage ?? "";
  },
  set: (value) => {
    emit("update:documentLanguage", value);
  },
});

const documentLanguages = ref<DocumentLanguage[]>([]);
const fetchDocumentLanguages = async () => {
  const response = await documentLanguageRepository.getAll();
  documentLanguages.value = response.map((languageId: string): DocumentLanguage => {
    return {
      id: languageId,
      name: t(`common.document-language.${languageId}`),
    };
  });
};
onMounted(fetchDocumentLanguages);

const rules = {
  documentLanguage: {
    required,
  },
};

const val = useVuelidate(rules, props);
const isDropdownFocused = ref(false);
</script>
