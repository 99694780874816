<template>
  <BeforeUnloadBrowserTab :hasUpdates="hasUpdates" />
  <SupplierHeader
    :editMode="false"
    :hasUpdates="hasUpdates"
    :unsavedChangesDialogVisible="unsavedChangesDialogVisible"
    :collapsedPanels="allPanelsCollapsed"
    @onCancel="onCancel"
    @saveClicked="addSupplier"
    @dialogCancelBtnClicked="stayOnPage"
    @dialogDiscardBtnClicked="routeToSupplierList"
    @onToggleAllPanels="($event : boolean) => onToggleAllPanels($event)"
  />
  <div class="c-content mr-4 mb-2">
    <div class="grid c-content-top mx-3">
      <div class="c-content-top-left">
        <div class="c-card flex flex-wrap ml-3 pb-0">
          <div class="c-col-1 px-2 -mx-3">
            <div class="mb-4">
              <SupplierNumber v-model:supplierNumber="supplier.supplierNumber" :isEdit="true" />
            </div>

            <div class="mb-2">
              <Country v-model:countryIso="supplier.countryIso" :countryOptions="countryOptions" />
            </div>

            <div class="mb-2 mt-4">
              <DocumentLanguage v-model:documentLanguage="supplier.documentLanguage" />
            </div>
          </div>

          <div class="c-col-2 -mx-3 px-4 mt-0">
            <div class="-mr-3 mb-4">
              <Name v-model:name="supplier.name" ref="supplierNameRef" :focus-input="true" />
            </div>

            <div class="-mr-3 mb-4">
              <Email v-model:email="supplier.email" />
            </div>
            <div class="-mr-3 mb-4">
              <Clients v-model:selectedClientIds="supplier.clientIds" />
            </div>
          </div>
        </div>
      </div>
      <div class="c-content-top-right">
        <div class="c-card flex flex-wrap mr-0">
          <div class="c-col-1 px-2 -mx-3">
            <div class="-mr-3 mb-4">
              <BusinessNumber v-model:businessNumber="supplier.businessNumber" />
            </div>

            <div class="mb-7">
              <PhoneNumber v-model:phoneNumber="supplier.phoneNumber" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="c-content mr-4" :class="{ 'mb-6 ': !panels.get('addressPanelCollapsed') }">
    <div class="grid c-content-top mx-4">
      <div class="c-card col-12 py-0">
        <div class="c-card-body">
          <div class="formgrid grid">
            <CumulusPanel
              toggle-test-id="c-addresses-panel"
              :collapsed="panels.get('addressPanelCollapsed')"
              @onCollapsedChange="panels.set('addressPanelCollapsed', $event)"
            >
              <template #title> {{ t("supplier.addresses.label") }}</template>
              <template #content>
                <div class="formgrid grid flex-column lg:flex-row">
                  <div class="field w-full flex-1 pr-3 c-border-div" data-testid="company-address">
                    <label class="inline-block mb-3 text-base">{{ t("supplier.addresses.company-address") }}</label>
                    <Address
                      :selectedCountry="supplier.countryIso"
                      :address="supplier.companyAddress"
                      :countryOptions="countryOptions"
                      @setAddress="setCompanyAddress"
                      @autoSelectCountry="autoSelectedCountry"
                      :address-type="addressTypeCompany"
                    />
                  </div>
                  <div class="field w-full flex-1 pr-3 pl-3 c-border-div" data-testid="return-address">
                    <label class="inline-block mb-3 text-base">{{ t("supplier.addresses.return-address") }}</label>
                    <Address
                      :selected-country="supplier.countryIso"
                      :address="supplier.returnAddress"
                      :countryOptions="countryOptions"
                      @setAddress="setReturnAddress"
                      :address-type="addressTypeReturn"
                    />
                  </div>
                  <div class="field w-full flex-1 pl-3 c-border-div" data-testid="invoice-address">
                    <label class="inline-block mb-3 text-base c-faded-label ml-3">
                      {{ t("supplier.addresses.invoice-address") }}
                    </label>
                    <Address
                      :selected-country="supplier.countryIso"
                      :address="supplier.invoiceAddress"
                      :countryOptions="countryOptions"
                      @setAddress="setInvoiceAddress"
                      :address-type="addressTypeInvoice"
                    />
                  </div>
                </div>
              </template>
            </CumulusPanel>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="c-content mr-4" :class="{ 'mb-6 ': !panels.get('addressPanelCollapsed') }">
    <div class="grid c-content-top mx-4">
      <div class="c-card col-12 py-0">
        <div class="c-card-body">
          <div class="formgrid grid">
            <CumulusPanel
              toggle-test-id="c-contacts-panel"
              :collapsed="panels.get('contactsPanelCollapsed')"
              @onCollapsedChange="panels.set('contactsPanelCollapsed', $event)"
            >
              <template #title>{{ t("supplier.contacts.header") }} </template>
              <template #content>
                <div class="field">
                  <ContactList v-model:contacts="supplier.contacts" />
                </div>
              </template>
            </CumulusPanel>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="c-content mr-4" :class="{ 'mb-6 ': !panels.get('addressPanelCollapsed') }">
    <div class="grid c-content-top mx-4">
      <div class="c-card col-12 py-0">
        <div class="c-card-body">
          <div class="formgrid grid">
            <CumulusPanel
              toggle-test-id="c-conditions-and-freight-panel"
              :collapsed="panels.get('conditionsAndFreightPanelCollapsed')"
              @onCollapsedChange="panels.set('conditionsAndFreightPanelCollapsed', $event)"
            >
              <template #title>{{ t("supplier.condition-and-freight") }}</template>
              <template #content>
                <div class="grid">
                  <div class="flex flex-wrap col-12 lg:col-6 c-border-div">
                    <div class="flex-1 field mx-2 mt-3">
                      <Currency v-model:currency="supplier.currency" />
                    </div>
                    <div class="flex-1 field mx-2 mt-3">
                      <DefaultPaymentTerm v-model:paymentTermId="supplier.defaultPaymentTermId" />
                    </div>
                    <div class="flex-1 field mx-2 mt-3">
                      <Incoterms v-model:incoterms="supplier.incoterms" />
                    </div>
                    <div class="flex-1 field mx-2 mt-1">
                      <EstimatedDeliveryDays v-model:estimatedDeliveryDays="supplier.estimatedDeliveryDays" />
                    </div>
                  </div>
                  <div class="flex flex-wrap col-12 lg:col-6">
                    <div class="flex-1 field mx-2 mt-3">
                      <FreightMethod v-model:freightMethodId="supplier.freightMethodId" />
                    </div>
                    <div class="flex-1 field mx-2 mt-3">
                      <FixedShipping v-model:fixedShipping="supplier.fixedShipping" />
                    </div>
                    <div class="flex-1 field mx-2 mt-3">
                      <FreeShipping v-model:freeFreight="supplier.freeShippingFrom" />
                    </div>
                  </div>
                </div>
              </template>
            </CumulusPanel>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref, nextTick } from "vue";
import { onBeforeRouteLeave, useRouter } from "vue-router";
import useValidate from "@vuelidate/core";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/toast";
import { BeforeUnloadBrowserTab } from "@cumulus/components";
import { CumulusPanel } from "@cumulus/panel";
import { useI18n } from "vue-i18n";

import { Supplier } from "@/repositories/supplier/model/Supplier";
import { Country as CountryModel } from "@/models/country/Country";
import { SupplierContact } from "@/repositories/supplier/model/SupplierContact";
import { supplierAddressFunctions } from "../SupplierAddressFunctions";
import { useSupplier } from "@/repositories/supplier/SupplierService";
import { useCountry } from "@/api/country/CountryService";
import BusinessNumber from "../components/BusinessNumber.vue";
import SupplierHeader from "../components/SupplierHeader.vue";
import FreightMethod from "../components/FreightMethod.vue";
import Country from "../components/Country.vue";
import PhoneNumber from "../components/PhoneNumber.vue";
import Address from "../components/Address.vue";
import Incoterms from "../components/Incoterms.vue";
import Email from "../components/Email.vue";
import DefaultPaymentTerm from "../components/DefaultPaymentTerm.vue";
import ContactList from "../components/ContactList.vue";
import SupplierNumber from "../components/SupplierNumber.vue";
import Name from "../components/Name.vue";
import Currency from "../components/Currency.vue";
import DocumentLanguage from "../components/DocumentLanguage.vue";
import Clients from "../components/Clients.vue";
import FixedShipping from "../components/FixedShipping.vue";
import FreeShipping from "../components/FreeShipping.vue";
import EstimatedDeliveryDays from "../components/EstimatedDeliveryDays.vue";
import cloneDeep from "lodash.clonedeep";
import isEqual from "lodash.isequal";
import { useAuth } from "@cumulus/event-bus";
import { useClient } from "@/api/client/ClientService";
import { useCurrency } from "@/api/currency/CurrencyService";

const { t } = useI18n();
const router = useRouter();
const toast = useCumulusToast(useToast());
const { createSupplier } = useSupplier();
const { getAllCountries } = useCountry();
const countryOptions = ref<CountryModel[]>([]);
const addressTypeInvoice = ref("invoice");
const addressTypeReturn = ref("return");
const addressTypeCompany = ref("company");

const previouslyFocusedInput = ref<HTMLInputElement | null>(null);
const unsavedChangesDialogVisible = ref(false);

const supplier = ref<Supplier>(new Supplier());

const initialSupplier = ref<Supplier>(new Supplier());
const { getCurrency } = useCurrency();
const { getAuthHeaders } = useAuth();
const { getClient } = useClient();

onMounted(async () => {
  const defaultContact = new SupplierContact();
  defaultContact.isDefaultContact = true;
  supplier.value.contacts.push(defaultContact);

  const client = await getClient((await getAuthHeaders()).clientId);
  supplier.value.countryIso = client.countryIso;
  supplier.value.currency = await getCurrency(client.currencyIso);
  supplier.value.documentLanguage = client.countryIso;
  initialSupplier.value = cloneDeep(supplier.value);
});

const { setCompanyAddress, setReturnAddress, setInvoiceAddress } = supplierAddressFunctions(supplier);

//Nested validation
const val = useValidate();

const supplierNameRef = ref();
const confirmedDiscard = ref(false);

const panels = ref(
  new Map<string, boolean>([
    ["addressPanelCollapsed", false],
    ["contactsPanelCollapsed", false],
    ["conditionsAndFreightPanelCollapsed", false],
  ])
);

const allPanelsCollapsed = computed(() => {
  return Array.from(panels.value.values()).every((panelCollapsed) => panelCollapsed === true);
});

const onToggleAllPanels = (currentValue: boolean) => {
  panels.value.forEach((_, key) => panels.value.set(key, !currentValue));
};

const routeToSupplierList = () => {
  confirmedDiscard.value = true;
  if (window.history.state.back === null || window.history.state.back.indexOf("/supplier/search") === -1) {
    router.push({ name: "supplier-search", query: { search: "" } });
  } else {
    router.back();
  }
};

const addSupplier = async () => {
  val.value.$touch();
  await val.value.$validate();
  if (val.value.$error) {
    toast.add({
      severity: "warn",
      summary: t("common.validation-error.summary"),
      detail: t("common.validation-error.detail"),
      closable: true,
    });
    return;
  }

  await createSupplier(supplier.value);

  toast.add({
    severity: "success",
    summary: t("supplier.toast.add-success.summary"),
    detail: t("supplier.toast.add-success.detail", { name: supplier.value.name }),
    closable: true,
  });

  routeToSupplierList();
};

const fetchCountryData = async () => {
  countryOptions.value = await getAllCountries();
};

const autoSelectedCountry = (countryIso: string) => {
  if (supplier.value.companyAddress.countryIso === "") {
    supplier.value.companyAddress.countryIso = countryIso;
    supplier.value.invoiceAddress.countryIso = countryIso;
    supplier.value.returnAddress.countryIso = countryIso;
  }
};

const hasUpdates = computed(() => {
  return !isEqual(supplier.value, initialSupplier.value);
});

const onCancel = () => {
  previouslyFocusedInput.value = document.activeElement as HTMLInputElement;
  if (hasUpdates.value === true) {
    unsavedChangesDialogVisible.value = true;
  } else {
    routeToSupplierList();
  }
};

const stayOnPage = () => {
  unsavedChangesDialogVisible.value = false;
  if (previouslyFocusedInput.value) {
    previouslyFocusedInput.value.focus();
  }
};

onMounted(async () => {
  document.addEventListener("keydown", handleKeydown);
  focusInput();
  fetchCountryData();
});

const handleKeydown = (event: KeyboardEvent) => {
  if (event.key === "Escape") {
    if (unsavedChangesDialogVisible.value) {
      stayOnPage();
    } else onCancel();
  } else if (event.ctrlKey && event.key === "i") {
    if (unsavedChangesDialogVisible.value) {
      routeToSupplierList();
    }
  }
};

onBeforeUnmount(() => {
  document.removeEventListener("keydown", handleKeydown);
});

const focusInput = async () => {
  await nextTick();
  supplierNameRef.value.focus();
};

window.addEventListener("beforeunload", (e) => {
  if (hasUpdates.value) {
    e.preventDefault();
    e.returnValue = t("common.unsaved-changes-header");
  }
});

onBeforeRouteLeave((_to, _from, next) => {
  if (hasUpdates.value && !confirmedDiscard.value) {
    unsavedChangesDialogVisible.value = true;
    next(false);
  } else {
    next();
  }
});
</script>

<style scoped lang="scss">
.c-supplier-add {
  margin: var(--default-content-margin);
  margin-bottom: 7rem;
}

.c-faded-label {
  color: var(--floating-label-color);
  font-size: 0.9rem;
  font-weight: normal;
}
.c-panel {
  box-shadow: none;
}

.c-border-div {
  padding: 0.5rem;
  border-right: var(--footer-border);

  @media screen and (max-width: 992px) {
    border-right: none;
  }
}

.c-border-div:last-child {
  border-right: none;
}
.c-content-top {
  display: flex;
  flex-wrap: wrap;
}
.c-content-top-left {
  flex: 75%;
}
.c-content-top-right {
  flex: 25%;
}

.c-col-1 {
  flex: 50%;
}
.c-col-2 {
  flex: 50%;
}

@media (max-width: 1024px) {
  .c-content-top-left,
  .c-content-top-right {
    flex: 100%;
  }
}

.c-card {
  font-size: 12.8px;
}

.c-content {
  position: relative;
  transition: all 0.25s;
}

.c-spinner-container {
  position: relative;
  top: 175px;
}
:deep(input#supplier-fixed-shipping) {
  padding: 0.2rem;
  font-size: 1.3rem;
}
:deep(input#supplier-estimated-delivery) {
  padding: 0.2rem;
  font-size: 1.3rem;
}
:deep(input#supplier-free-shipping) {
  padding: 0.2rem;
  font-size: 1.3rem;
}
</style>
