<template>
  <FloatLabel
    v-model:value="selectedPhoneNumber"
    type="tel"
    data-testid="supplier-phone-number"
    class="inputfield w-full pt-2"
    :class="{ 'p-invalid': val.phoneNumber.$error }"
    :placeholder="t('placeholder.type', { property: t('supplier.phone-number').toLowerCase() })"
    :selectAllOnFocus="true"
    :isRequired="true"
    :label="t('supplier.phone-number')"
    aria-describedby="supplier-phone-number-help"
  />
  <small class="p-error" v-if="val.phoneNumber.$error" data-testid="phone-number-error">{{
    val.phoneNumber.$errors[0].$message
  }}</small>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import useValidate from "@vuelidate/core";
import { computed } from "vue";
import { required, minLength } from "@/locales/i18n-validators";
import { helpers } from "@vuelidate/validators";

const props = defineProps<{
  phoneNumber: string;
}>();

const emit = defineEmits<{
  (e: "update:phoneNumber", value: string | null): void;
}>();

const selectedPhoneNumber = computed<string>({
  get: () => {
    return props.phoneNumber ?? "";
  },
  set: (value) => {
    emit("update:phoneNumber", value);
  },
});

const { t } = useI18n();

const rules = {
  phoneNumber: {
    required,
    minLength: helpers.withMessage(() => t("validations.min-length"), minLength(8)),
  },
};

const val = useValidate(rules, props);
</script>
