import { i18n } from "./locales/i18n";
import { createApp } from "vue";

import PrimeVue from "primevue/config";
import Button from "primevue/button";
import Checkbox from "primevue/checkbox";
import InputText from "primevue/inputtext";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";
import Chips from "primevue/chips";
import Panel from "primevue/panel";
import InputNumber from "primevue/inputnumber";
import ProgressSpinner from "primevue/progressspinner";
import InputMask from "primevue/inputmask";
import Dropdown from "primevue/dropdown";
import ToastService from "primevue/toastservice";
import ConfirmationService from "primevue/confirmationservice";
import ConfirmPopup from "primevue/confirmpopup";
import SplitButton from "primevue/splitbutton";
import Toast from "primevue/toast";
import Tooltip from "primevue/tooltip";
import MultiSelect from "primevue/multiselect";
import Textarea from "primevue/textarea";
import AutoComplete from "primevue/autocomplete";
import Dialog from "primevue/dialog";
import { FloatLabelInput } from "@cumulus/components";
import Menu from "primevue/menu";

import vueDebounce from "vue-debounce";
import { createPinia } from "pinia";

export default function configureApp(app: ReturnType<typeof createApp>) {
  const pinia = createPinia();

  app.use(PrimeVue);
  app.use(ConfirmationService);
  app.use(ToastService);
  app.use(i18n);
  app.use(pinia);

  app.component("Toast", Toast);
  app.component("PrimeButton", Button);
  app.component("SplitButton", SplitButton);
  app.component("Checkbox", Checkbox);
  app.component("InputText", InputText);
  app.component("DataTable", DataTable);
  app.component("Column", Column);
  app.component("ColumnGroup", ColumnGroup);
  app.component("InputNumber", InputNumber);
  app.component("Dropdown", Dropdown);
  app.component("ConfirmPopup", ConfirmPopup);
  app.component("InputMask", InputMask);
  app.component("Panel", Panel);
  app.component("PrimeTextarea", Textarea);
  app.component("MultiSelect", MultiSelect);
  app.component("Chips", Chips);
  app.component("AutoComplete", AutoComplete);
  app.component("ProgressSpinner", ProgressSpinner);
  app.component("PrimeDialog", Dialog);
  app.component("FloatLabel", FloatLabelInput);
  app.component("FloatLabelInput", FloatLabelInput);

  app.component("PrimeMenu", Menu);
  app.directive("tooltip", Tooltip);

  app.directive("debounce", vueDebounce({ lock: true }));
}
