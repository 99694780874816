<template>
  <div class="p-float-label">
    <label for="supplier-country" class="c-required c-floating-label">
      {{ t(`supplier.country`) }}
    </label>
    <div>
      <AutoComplete
        v-model="selectedCountry"
        :suggestions="filteredCountries"
        @complete="searchCountry($event)"
        @change="onCountryChange($event as unknown as AutoCompleteChangeEvent)"
        optionLabel="name"
        class="w-full mt-1"
        :inputProps="{ class: { 'p-invalid': val.countryIso.$error } }"
        :placeholder="t('placeholder.select', { property: t('supplier.country').toLowerCase() })"
        data-testid="supplier-country"
        forceSelection
        dropdown
        @show="isActive = true"
        @hide="isActive = false"
        @keydown.esc="isActive === true ? $event.stopPropagation() : ''"
      >
        <template #item="slotProps">
          <div class="country-item">
            <img
              src="../../images/flag_placeholder.png"
              :class="'flag flag-' + slotProps.item.iso.toLowerCase()"
              width="18"
            />
            <div class="ml-2">{{ slotProps.item.name }}</div>
          </div>
        </template>
      </AutoComplete>
    </div>
    <small class="p-error" v-if="val.countryIso.$error" data-testid="supplier-country-error">
      {{ val.countryIso.$errors[0].$message }}
    </small>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import useValidate from "@vuelidate/core";
import { required } from "@/locales/i18n-validators";
import { Country } from "@/models/country/Country";
import { AutoCompleteChangeEvent, AutoCompleteCompleteEvent } from "primevue/autocomplete";

const props = defineProps<{
  countryIso: string;
  countryOptions: Country[];
}>();

const emit = defineEmits<{
  (e: "update:countryIso", value: string): void;
}>();

const { t } = useI18n();
const filteredCountries = ref<Country[]>([]);
const selectedCountry = ref<Country | null>(null);

const isActive = ref(false);

const searchCountry = (event: AutoCompleteCompleteEvent) => {
  if (!event.query.trim().length) {
    filteredCountries.value = [...props.countryOptions];
  } else {
    filteredCountries.value = props.countryOptions.filter((country) => {
      return country.name.toLowerCase().startsWith(event.query.toLowerCase());
    });
  }
};

const onCountryChange = (event: AutoCompleteChangeEvent) => {
  if (event.value) {
    const country = props.countryOptions.find((country) => country.iso === event.value.iso);
    country ? emit("update:countryIso", country.iso) : emit("update:countryIso", "");
  } else {
    emit("update:countryIso", "");
  }
};

watch(
  () => [props.countryIso, props.countryOptions],
  () => {
    const country = props.countryOptions.find((country) => country.iso === props.countryIso);
    selectedCountry.value = country ?? null;
  }
);

const rules = {
  countryIso: {
    required,
  },
};

const val = useValidate(rules, props);
</script>

<style lang="scss" scoped>
.country-item {
  display: flex;
  img {
    margin-right: 0.5rem;
  }
}
</style>
