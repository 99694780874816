<template>
  <div class="p-float-label">
    <FloatLabelInputNumber
      type="number"
      id="supplier-fixed-shipping"
      mode="decimal"
      :min="0"
      :maxFractionDigits="5"
      v-model:value="fixedShippingComputed"
      data-testid="supplier-fixed-shipping"
      class="inputfield w-full"
      :label="t('supplier.fixed-shipping')"
      inputId="fixed-shipping"
      :isFloatingLabel="true"
    />
  </div>
</template>

<script setup lang="ts">
import { FloatLabelInputNumber } from "@cumulus/components";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps<{
  fixedShipping: number;
}>();

const emit = defineEmits<{
  (e: "update:fixedShipping", value: number | null): void;
}>();

const fixedShippingComputed = computed<number>({
  get: () => {
    return props.fixedShipping ?? 0;
  },
  set: (value) => {
    if (value) {
      emit("update:fixedShipping", value);
    }
  },
});
</script>
