<template>
  <div class="field">
    <FloatLabel
      :id="`${addressType}-address-lines`"
      v-model:value="addressLinesComputed"
      type="text"
      class="inputfield w-full"
      :placeholder="t('common.address.address-lines.placeholder')"
      :class="{ 'p-invalid': val.addressLines.$error }"
      @change="onChangeComplete"
      :data-testid="`${addressType}-address-lines`"
      :selectAllOnFocus="true"
      :isRequired="true"
      :label="t('common.address.address-lines.label')"
    />
    <small class="p-error" v-if="val.addressLines.$error" :id="`${addressType}-address-lines-error`">
      {{ val.addressLines.$errors[0].$message }}
    </small>
  </div>
  <div class="field pt-3">
    <div class="p-float-label">
      <Dropdown
        :id="`${addressType}-address-country`"
        :options="countryOptions"
        optionLabel="name"
        optionValue="iso"
        v-model="selectedCountryComputed"
        class="inputfield w-full"
        :class="{ 'p-invalid': val.countryIso.$error }"
        :placeholder="t('placeholder.type', { property: t('common.address.country').toLowerCase() })"
        @change="onChangeComplete"
        @show="isActive = true"
        @hide="isActive = false"
        @focus="isDropdownFocused = true"
        @blur="isDropdownFocused = false"
        @keydown.esc="isActive === true ? $event.stopPropagation() : ''"
      />
      <label
        :for="`${addressType}-address-country`"
        class="c-required c-floating-label"
        :class="{ 'c-focused': isDropdownFocused }"
        >{{ t("common.address.country") }}</label
      >
      <small class="p-error" v-if="val.countryIso.$error" :id="`${addressType}-address-country-error`">{{
        val.countryIso.$errors[0].$message
      }}</small>
    </div>
  </div>
  <div class="field pt-3">
    <div class="col p-0">
      <div class="formgrid grid">
        <div class="field col-12 md:col-4">
          <FloatLabel
            :data-testid="`${addressType}-address-postal-code`"
            :id="`${addressType}-address-postal-code`"
            v-model:value="addressComputed.postalCode"
            maxlength="10"
            type="text"
            class="inputfield w-full"
            :class="{
              'p-invalid': val.postalCode.$error,
            }"
            :placeholder="t('placeholder.type', { property: t('common.address.postal-code').toLowerCase() })"
            @change="onPostalCodeChange(($event.target as HTMLInputElement).value)"
            :selectAllOnFocus="true"
            :isRequired="true"
            @focus="isDropdownFocused = true"
            @blur="isDropdownFocused = false"
            v-debounce:100="formatPostCode"
            :label="t('common.address.postal-code')"
          />
          <small class="p-error" v-if="val.postalCode.$error" :id="`${addressType}-address-postal-code-error`">{{
            val.postalCode.$errors[0].$message
          }}</small>
        </div>
        <div class="field col-12 md:col-8">
          <FloatLabel
            :data-testid="`${addressType}-address-city`"
            :id="`${addressType}-address-city`"
            type="text"
            v-model:value="addressComputed.city"
            class="inputfield w-full"
            :class="{ 'p-invalid': val.city.$error }"
            :placeholder="t('placeholder.type', { property: t('common.address.city').toLowerCase() })"
            @change="onChangeComplete"
            :selectAllOnFocus="true"
            :isRequired="true"
            :label="t('common.address.city')"
          />
          <small class="p-error" v-if="val.city.$error" :id="`${addressType}-address-city-error`">{{
            val.city.$errors[0].$message
          }}</small>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Country } from "@/models/country/Country";
import { SupplierAddress } from "@/repositories/supplier/model/SupplierAddress";
import useVuelidate from "@vuelidate/core";
import { helpers } from "@vuelidate/validators";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { required } from "@/locales/i18n-validators";
import cloneDeep from "lodash.clonedeep";
import { useCountry } from "@/api/country/CountryService";

const { t } = useI18n();
const { getCity } = useCountry();

const props = defineProps<{
  address: SupplierAddress;
  countryOptions: Country[];
  addressType: string;
  selectedCountry: string;
}>();

const emit = defineEmits<{
  (e: "setAddress", value: SupplierAddress): void;
  (e: "autoSelectCountry", value: string): void;
}>();

const isActive = ref(false);

const addressComputed = computed<SupplierAddress>(() => {
  return props.address ? cloneDeep(props.address) : new SupplierAddress();
});

const addressLinesComputed = computed<string>({
  get: () => {
    return addressComputed.value.addressLines.join("\n");
  },
  set: (val) => {
    addressComputed.value.addressLines = val.toString().replace(/\r\n/g, "\n").split("\n");
  },
});

const selectedCountryComputed = computed<string>({
  get: () => {
    if (props.selectedCountry) {
      emit("autoSelectCountry", props.selectedCountry);
    }
    return addressComputed.value.countryIso;
  },
  set: (value: string) => {
    addressComputed.value.countryIso = value;
  },
});

const onChangeComplete = () => {
  emit("setAddress", addressComputed.value);
};

const onPostalCodeChange = async (code: string) => {
  if (code.trim() === "") {
    return;
  }
  if (addressComputed.value.countryIso == "NO") {
    const response = await getCity(code);
    addressComputed.value.city = response[0].city;
  }
  onChangeComplete();
};

const formatPostCode = async (code: string) => {
  if (addressComputed.value.countryIso == "SE") {
    addressComputed.value.postalCode = code.replace(/(.{3})/g, "$1 ").trim();

    return addressComputed.value.postalCode;
  }
};

const rules = {
  addressLines: {
    required,
    $each: helpers.withMessage(t("common.address.address-lines.error"), (value: string[]) => {
      return value !== undefined && value[0].length > 1;
    }),
  },
  postalCode: {
    required,
  },
  city: {
    required,
  },
  countryIso: {
    required,
  },
};

const isDropdownFocused = ref(false);
const val = useVuelidate(rules, addressComputed);
</script>

<style scoped lang="scss">
.c-faded-label {
  color: var(--floating-label-color);
  font-size: 0.9rem;
  font-weight: normal;
}
</style>
