<template>
  <div class="p-float-label">
    <FloatLabelInputNumber
      type="number"
      id="supplier-free-shipping"
      mode="decimal"
      :min="0"
      :maxFractionDigits="5"
      v-model:value="freeFreightComputed"
      data-testid="supplier-free-shipping"
      class="inputfield w-full"
      :label="t('supplier.free-shipping')"
      inputId="free-shipping"
      :isFloatingLabel="true"
      @keydown="handleKeyPress"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, nextTick } from "vue";
import { useI18n } from "vue-i18n";
import { FloatLabelInputNumber } from "@cumulus/components";
const { t } = useI18n();

const props = defineProps<{
  freeFreight: number;
}>();

const emit = defineEmits<{
  (e: "update:freeFreight", value: number | null): void;
}>();

const freeFreightComputed = computed<number>({
  get: () => {
    return props.freeFreight ?? 0;
  },
  set: (value) => {
    if (value) {
      emit("update:freeFreight", value);
    }
  },
});

const handleKeyPress = (event: KeyboardEvent) => {
  const elementBtnComit = document.getElementById("btn-save");
  const elementBtnCancel = document.getElementById("btn-cancel");

  if (event.key === "Tab" && event.shiftKey === false) {
    if (elementBtnComit?.classList.contains("p-disabled")) {
      nextTick(() => elementBtnCancel?.focus());
    } else {
      nextTick(() => elementBtnComit?.focus());
    }
    event.preventDefault();
  }
};
</script>
