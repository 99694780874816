<template>
  <div class="supplier-estimated-delivery">
    <div class="p-float-label pt-1">
      <FloatLabelInputNumber
        id="supplier-estimated-delivery"
        :allow-empty="false"
        :min="0"
        :maxFractionDigits="0"
        v-model:value="estimatedDeliveryDays"
        data-testid="supplier-estimated-delivery"
        class="inputfield w-full"
        :label="t('supplier.estimated-delivery')"
        :isFloatingLabel="true"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { FloatLabelInputNumber } from "@cumulus/components";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const estimatedDeliveryDays = defineModel("estimatedDeliveryDays");
</script>
