<template>
  <FloatLabel
    id="email"
    v-model:value="selectedEmail"
    data-testid="supplier-email"
    class="inputfield w-full pt-2"
    :class="{ 'p-invalid': val.email.$error }"
    :placeholder="t('placeholder.type', { property: t('supplier.email').toLowerCase() })"
    :selectAllOnFocus="true"
    :isRequired="false"
    :label="t('supplier.email')"
    maxlength="8000"
  />
  <small class="p-error" v-if="val.email.$error" data-testid="supplier-email-error">
    {{ val.email.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import useValidate from "@vuelidate/core";
import { helpers } from "@vuelidate/validators";
import { computed, WritableComputedRef } from "vue";
import { required, email as validateEmail } from "@/locales/i18n-validators";

const props = defineProps<{
  email: string;
}>();

const emit = defineEmits<{
  (e: "update:email", value: string | null): void;
}>();

const selectedEmail: WritableComputedRef<string> = computed({
  get: () => {
    return props.email ?? "";
  },
  set: (value) => {
    emit("update:email", value);
  },
});

const { t } = useI18n();

const rules = {
  email: {
    required,
    validateEmail: helpers.withMessage(() => t("validations.email"), validateEmail),
  },
};

const val = useValidate(rules, props);
</script>
