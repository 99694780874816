<template>
  <CumulusRightSidebar v-model:showSidebar="showSidebarComputed" :enableDragover="false" :sidebarWidth="260">
    <template v-slot:header>
      <div class="c-filter-header-container">
        <i class="pi pi-sliders-h"></i>
        <h1>{{ t("supplier.search.filter") }}</h1>
      </div>
    </template>
    <template v-slot:body>
      <h4 class="text-center">{{ t("supplier.search.supplier-state") }}</h4>
      <div
        v-for="(value, index) in allStatusComputed"
        :key="index"
        class="flex justify-content-between relative align-items-center p-2 border-round-md"
        :class="{ 'c-list-row-bg mt-1': index % 2 === 0, 'c-list-alternate-row-bg mt-1': index % 2 === 1 }"
      >
        <div class="centered-child">
          <label class="font-light" :for="'supplier-status-' + value.type.toLowerCase()">{{
            t(`supplier.status.${value.type.toLowerCase()}`)
          }}</label>
        </div>
        <div class="ml-auto">
          <Checkbox
            v-model="statusesComputed"
            :value="value.type"
            :inputId="'supplier-status-' + value.type.toLowerCase()"
            :data-testid="'supplier-status-' + value.type.toLowerCase()"
          />
        </div>
      </div>
    </template>
  </CumulusRightSidebar>
</template>
<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { CumulusRightSidebar } from "@cumulus/components";
import { PurchasePermit } from "@/repositories/supplier/model/PurchasePermit";
import { SearchFilters } from "@/repositories/search/model/SearchFilters";

const { t } = useI18n();

const props = defineProps<{
  showSidebar: boolean;
  filters: SearchFilters;
}>();

const emit = defineEmits<{
  (event: "update:showSidebar", value: boolean): void;
  (event: "update:filters", value: SearchFilters): void;
}>();

const showSidebarComputed = computed<boolean>({
  get: () => props.showSidebar,
  set: (value) => emit("update:showSidebar", value),
});

const statusesComputed = computed<PurchasePermit[]>({
  get: () => props.filters.supplierStatus,
  set: (value) => {
    const filters = { ...props.filters, supplierStatus: value };
    emit("update:filters", filters);
  },
});

const allStatusComputed = computed(() => {
  const allTypes = Object.values(PurchasePermit);
  return allTypes.map((type, index) => {
    return {
      type: type,
      index: index,
    };
  });
});
</script>

<style scoped lang="scss">
h4,
label {
  font-family: var(--font-family);
}
h4 {
  margin-bottom: 0.5rem;
  margin-top: 1.5rem;
}

.c-list-row-bg {
  background-color: var(--list-row-bg);
}
.c-list-alternate-row-bg {
  background-color: var(--list-alternate-row-bg);
}
</style>
