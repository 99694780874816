{
  "validations": {
    "required": "Verdien er påkrevd",
    "email": "Må være en gyldig e-post adresse",
    "min-length": "{property} lengde er {min}",
    "exists": "{property} finnes fra før",
    "supplier-number-already-in-use": "Leverandørnummer er allerede i bruk",
    "start-number-must-be-greater-than-last-used": "Startnummer må være større enn siste brukte leverandørnummer"
  },
  "placeholder": {
    "select": "Velg {property}",
    "type": "Skriv inn {property}"
  },
  "common": {
    "created": "Opprettet",
    "add": "Legg til",
    "update": "Oppdater",
    "save": "Lagre",
    "cancel": "Avbryt",
    "search": "Søk",
    "delete": "Slett",
    "preview": "Forhåndsvisning",
    "reload": "Last inn på nytt",
    "column-chooser": "Kolonnevelger",
    "reset": "Tilbakestill kolonner",
    "current-page-template": "Viser {first} til {last} av totalt {totalRecords} leverandører",
    "unsaved-changes-header": "Ulagrede endringer",
    "unsaved-changes-text": "Du har endringer som ikke er lagret. Lagre dine endringene eller Forkast for å forlate uten å lagre. Velg Avbryt for a fortsette redigeringen",
    "discard": "Forkast",
    "confirm-delete": "Vil du slette dette elementet?",
    "save-tooltip": "Ingen endringer",
    "validation-error": {
      "summary": "Validering feilet",
      "detail": "Rett alle rød felter og prøv igjen"
    },
    "copy": "Kopier",
    "document-language": {
      "NO": "Norsk",
      "DK": "Dansk",
      "FI": "Finsk",
      "SE": "Svensk",
      "DE": "Tysk",
      "IT": "Italiensk",
      "FR": "Fransk",
      "EN": "Engelsk"
    },
    "address": {
      "address-lines": {
        "label": "Adresselinjer",
        "placeholder": "Skriv inn adresselinjer, skill med enter",
        "error": "Minst en adresselinje er påkrevet"
      },
      "postal-code-city": "Postnr./sted",
      "postal-code": "Postnr.",
      "city": "By",
      "country": "Land"
    },
    "error-detail": {
      "unknown": "Ukjent feil",
      "server-error": "Server feil"
    }
  },
  "supplier": {
    "title": "Leverandør",
    "add-supplier": "Legg Til Leverandør",
    "edit-supplier": "Endre Leverandør",
    "search-supplier": "Søk Leverandør",
    "registered": "Registrert",
    "created": "Opprettet:",
    "load-error": "Kunne ikke laste inn leverandøren, årsak: ",
    "name": "Navn",
    "business-number": "Foretaksnummer",
    "country": "Land",
    "email": "E-post",
    "phone-number": "Telefonnummer",
    "document-language": "Dokumentspråk",
    "clients": "Klienter",
    "number": {
      "label": "Leverandørnummer",
      "placeholder": "Automatisk tildeling, klikk for å overstyre"
    },
    "header": {
      "add-supplier": "Legg til leverandør",
      "edit-supplier": "Rediger leverandør",
      "suppliers": "Leverandører",
      "purchase-permit": "Innkjøpstillatelse",
      "open-supplier": "Åpne",
      "refresh-supplier-list": "Last inn på nytt"
    },
    "status": {
      "open": "Åpen",
      "blocked": "Blokkert"
    },
    "supplier-status": "Leverandørstatus",
    "addresses": {
      "label": "Adresser",
      "company-address": "Firmaadresse",
      "invoice-address": "Fakturaadresse",
      "return-address": "Returadresse"
    },
    "contacts": {
      "first-name": "Fornavn",
      "last-name": "Etternavn",
      "email": "E-post",
      "phone-number": "Telefonnummer",
      "header": "Kontaktpersoner",
      "default-contact": "Hoved",
      "default-contact-selected": "Hoved kontakt valgt"
    },
    "condition-and-freight": "Betingelser og Frakt",
    "conditions": "Betingelser",
    "currency": "Valuta",
    "payment-term": "Betalingsbetingelse",
    "incoterms": "Leveringsbetingelser",
    "freight-method": "Fraktmåte",
    "freight": "Frakt",
    "fixed-shipping": "Fast frakt",
    "free-shipping": "Fraktfritt fra",
    "estimated-delivery": "Leveringstid",
    "delete": {
      "confirm-message": "Er du sikker på at du vil slette denne leverandøren?"
    },
    "toast": {
      "reload": "Laster inn leverandør..",
      "add-success": {
        "summary": "Leverandør er lagt til!",
        "detail": "Leverandørnavn: {name}"
      },
      "update-success": {
        "summary": "Leverandør er oppdatert!",
        "detail": "Leverandørnavn: {name}"
      },
      "delete-success": {
        "summary": "Leverandør er slettet!",
        "detail": "Leverandørnavn: {name}"
      },
      "error": {
        "summary": "En feil oppsto",
        "detail": "En ukjent feil oppsto, vennligst prøv igjen senere"
      },
      "open-error": {
        "open-supplier-failed": "Klarte ikke åpne leverandør.",
        "no-supplier-selected": "Ingen leverandør er valgt. Velg en leverandør og forsøk på nytt."
      }
    },
    "search": {
      "search-label": "Leverandørsøk:",
      "more-filters": "Flere filtre",
      "total-hits": "{totalHits} leverandører funnet",
      "advanced-btn": "Avansert",
      "select-all": "Velg alle",
      "delete-selected": "Slett valgte",
      "edit-columns": "Tilpass kolonner",
      "header-name": "Leverandørnavn",
      "header-phone-number": "Telefonnummer",
      "header-supplier-status": "Leverandørstatus",
      "search-error": "Søk feilet",
      "empty-list": "Ingen leverandører funnet.",
      "loading-list": "Laster inn leverandører, vennligst vent...",
      "current-page": "Viser {first} til {last} av {totalRecords}",
      "filter": "Filter",
      "show-filter": "Vis filter",
      "hide-filter": "Skjul filter",
      "clear-filter": "Tøm filter",
      "supplier-state": "Leverandør status"
    },
    "panel": {
      "close-panels": "Lukk faner",
      "open-panels": "Åpne faner"
    }
  }
}