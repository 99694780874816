import { httpClient, AuthHeaders } from "@cumulus/http";
import { SupplierSearchRequest } from "./model/SupplierSearchRequest";
import { SupplierSearchResponse } from "../search/model/SupplierSearchResponse";

class SearchApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_SEARCH != undefined && import.meta.env.VITE_APP_API_SEARCH
        ? import.meta.env.VITE_APP_API_SEARCH
        : `${import.meta.env.VITE_APP_API_URL as string}`;
  }

  public async supplierSearch(
    authHeaders: AuthHeaders,
    request: SupplierSearchRequest
  ): Promise<SupplierSearchResponse> {
    return await httpClient(this.uri, authHeaders)
      .post("/suppliers/search", request)
      .then(({ data }) => data);
  }
}
const searchApi = new SearchApi();
export { searchApi };
