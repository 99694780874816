<template>
  <FloatLabelInput
    id="supplier-name"
    ref="inputRef"
    type="text"
    v-model:value="nameComputed"
    data-testid="supplier-name"
    class="inputfield w-full pt-2"
    :class="{ 'p-invalid': val.name.$error }"
    :label="t('supplier.name')"
    :placeholder="t('placeholder.type', { property: t('supplier.name').toLowerCase() })"
    :isRequired="true"
    :focusInput="focusInput"
  />
  <small id="supplier-name-help" data-testid="supplier-name-error" class="p-error" v-if="val.name.$error">{{
    val.name.$errors[0].$message
  }}</small>
</template>

<script setup lang="ts">
import { useVuelidate } from "@vuelidate/core";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { required } from "@/locales/i18n-validators";

const { t } = useI18n();
const inputRef = ref();

const props = defineProps<{
  name: string;
}>();

const emit = defineEmits<{
  (e: "update:name", value: string): void;
}>();

const nameComputed = computed<string>({
  get: () => {
    return props.name ?? "";
  },
  set: (value) => {
    emit("update:name", value);
  },
});

const rules = {
  name: {
    required,
  },
};

const val = useVuelidate(rules, props);

const focus = () => {
  if (inputRef.value.$el) {
    inputRef.value.$el.focus();
  }
};
defineExpose({
  focus,
});
const focusInput = defineModel<boolean>("focusInput");
</script>
