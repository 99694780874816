<template>
  <div class="p-float-label">
    <Dropdown
      id="supplier-status"
      v-model="supplierStatusComputed"
      :options="options"
      optionLabel="label"
      optionValue="value"
      class="w-full"
      data-testid="supplier-purchase-permit"
      @show="isActive = true"
      @hide="isActive = false"
      @keydown.esc="isActive === true ? $event.stopPropagation() : ''"
    />
    <label for="supplier-status" class="c-floating-label">
      {{ t("supplier.supplier-status") }}
    </label>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, WritableComputedRef, onMounted } from "vue";
import { PurchasePermit } from "@/repositories/supplier/model/PurchasePermit";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  purchasePermit: PurchasePermit;
}>();

const emit = defineEmits<{
  (e: "update:purchasePermit", value: string): void;
}>();

const { t } = useI18n();

const isActive = ref(false);

const options = ref<Record<string, string>[]>([]);

const fetchSupplierStatus = () => {
  for (const state in PurchasePermit) {
    options.value.push({
      label: t(`supplier.status.${state.toString().toLowerCase()}`),
      value: state,
    });
  }
};

onMounted(fetchSupplierStatus);

const supplierStatusComputed: WritableComputedRef<PurchasePermit> = computed({
  get: () => {
    return props.purchasePermit ?? PurchasePermit.Open;
  },
  set: (value) => {
    emit("update:purchasePermit", value);
  },
});
</script>
