<template>
  <DataTable
    :value="contactsComputed"
    dataKey="id"
    data-testid="supplier-contacts"
    responsiveLayout="scroll"
    breakpoint="991px"
    class="c-contact-table"
  >
    <Column field="firstName">
      <template #body="{ data }">
        <FloatLabel
          v-model:value="data.firstName"
          class="w-full"
          id="contact-first-name"
          :placeholder="t('placeholder.type', { property: t('supplier.contacts.first-name').toLowerCase() })"
          @focus="selectAllOnFocus"
          @input="updateContacts"
          :selectAllOnFocus="true"
          :label="t('supplier.contacts.first-name')"
        />
      </template>
    </Column>

    <Column field="lastName">
      <template #body="{ data }">
        <FloatLabel
          v-model:value="data.lastName"
          class="w-full"
          id="contact-last-name"
          :placeholder="t('placeholder.type', { property: t('supplier.contacts.last-name').toLowerCase() })"
          @focus="selectAllOnFocus"
          @input="updateContacts"
          :selectAllOnFocus="true"
          :label="t('supplier.contacts.last-name')"
        />
      </template>
    </Column>

    <Column field="email">
      <template #body="{ data }">
        <ContactEmail
          v-model:email="data.email"
          @update:email="updateContacts"
          :contact="data"
          :contacts="contactsComputed"
        ></ContactEmail>
      </template>
    </Column>

    <Column field="phoneNumber" class="w-15rem">
      <template #body="{ data }">
        <FloatLabel
          class="w-full"
          id="contact-phone-number"
          v-model:value="data.phoneNumber"
          :maxlength="50"
          :placeholder="t('placeholder.type', { property: t('supplier.contacts.phone-number').toLowerCase() })"
          @focus="selectAllOnFocus"
          @input="updateContacts"
          :selectAllOnFocus="true"
          :label="t('supplier.contacts.phone-number')"
        />
      </template>
    </Column>

    <Column field="isDefaultContact" :header="t('supplier.contacts.default-contact')">
      <template #body="{ data }">
        <Checkbox
          v-model="data.isDefaultContact"
          id="contact-default"
          :binary="true"
          @change="updateDefaultContact(data)"
          :aria-label="
            data.isDefaultContact
              ? t('supplier.contacts.default-contact-selected')
              : t('supplier.contacts.default-contact')
          "
        />
      </template>
    </Column>

    <Column field="action">
      <template #body="{ data }">
        <PrimeButton
          id="contact-delete"
          icon="pi pi-trash"
          class="p-button-rounded p-button-secondary p-button-text"
          @click="(event: Event) => onConfirmDelete(event, data)"
          :aria-label="t('common.delete')"
        />
      </template>
    </Column>
  </DataTable>
  <ConfirmPopup data-testid="contact-delete-confirm"></ConfirmPopup>
  <PrimeButton
    type="button"
    class="p-button-text p-button-sm pl-0"
    data-testid="contact-add"
    @click="addContact"
    ref="addContactRef"
  >
    <i class="c-plus"></i>
    <span class="ml-2 c-default-text">{{ t("common.add") }}</span>
  </PrimeButton>
</template>

<script setup lang="ts">
import { v4 as uuidv4 } from "uuid";
import { SupplierContact } from "@/repositories/supplier/model/SupplierContact";
import { computed, nextTick, ref } from "vue";
import { useI18n } from "vue-i18n";
import ContactEmail from "./ContactEmail.vue";
import cloneDeep from "lodash.clonedeep";
import { useConfirm } from "primevue/useconfirm";

const confirm = useConfirm();

const { t } = useI18n();
const addContactRef = ref();

const props = defineProps<{
  contacts: SupplierContact[];
}>();

const emit = defineEmits<{
  (e: "update:contacts", value: SupplierContact[]): void;
}>();

const addContact = async () => {
  const newContact: SupplierContact = {
    id: uuidv4(),
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    isDefaultContact: false,
  };

  if (contactsComputed.value.length === 0) {
    newContact.isDefaultContact = true;
  }

  contactsComputed.value.push(newContact);
  updateContacts();
};

const contactsComputed = computed<SupplierContact[]>(() => {
  return props.contacts ? props.contacts.map((c) => cloneDeep(c)) : [];
});

const updateContacts = () => {
  emit("update:contacts", contactsComputed.value);
};

const updateDefaultContact = (contact: SupplierContact) => {
  contact.isDefaultContact = true;
  const index = contactsComputed.value.findIndex((x) => x.id === contact.id);

  if (index > -1) {
    if (contactsComputed.value[index].isDefaultContact) {
      for (let i = 0; i < contactsComputed.value.length; i++) {
        if (i === index) {
          continue;
        }
        contactsComputed.value[i].isDefaultContact = false;
      }
    }
  }

  updateContacts();
};

const deleteContact = async (contact: SupplierContact) => {
  const contacts = contactsComputed.value.filter((x) => x.id !== contact.id);
  if (contact.isDefaultContact && contacts.length > 0) {
    contacts[0].isDefaultContact = true;
  }
  emit("update:contacts", contacts);
  await nextTick();
  addContactRef.value.$el.focus();
};

const selectAllOnFocus = (event: FocusEvent) => {
  (event.target as HTMLInputElement).select();
};

const onConfirmDelete = (event: Event, contact: SupplierContact) => {
  confirm.require({
    target: event.currentTarget as HTMLElement,
    message: t("common.confirm-delete"),
    icon: "pi pi-exclamation-triangle",
    accept: async () => {
      deleteContact(contact);
    },
  });
};
</script>

<style scoped lang="scss">
.c-contact-table :deep(.p-datatable-thead > tr > th) {
  padding-left: 0 !important;
}

.c-contact-table :deep(.p-datatable-tbody > tr > td) {
  padding-left: 0 !important;
}

.c-contact-table :deep(.p-datatable-tbody > tr) {
  background-color: transparent !important;
}

.c-success-color {
  color: var(--success-color);
}

.c-text-color {
  color: var(--text-color);
}
</style>
