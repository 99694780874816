<template>
  <FloatLabel
    id="business-number"
    type="text"
    v-model:value="selectedBusinessNumber"
    data-testid="supplier-business-number"
    class="inputfield w-full pt-2"
    :placeholder="t('placeholder.type', { property: t('supplier.business-number').toLowerCase() })"
    maxlength="8000"
    :label="t('supplier.business-number')"
    :selectAllOnFocus="true"
  />
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { computed } from "vue";

const props = defineProps<{
  businessNumber: string;
}>();

const emit = defineEmits<{
  (e: "update:businessNumber", value: string | null): void;
}>();

const selectedBusinessNumber = computed<string>({
  get: () => {
    return props.businessNumber ?? "";
  },
  set: (value) => {
    emit("update:businessNumber", value);
  },
});

const { t } = useI18n();
</script>
